
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  data() {
    return {
      num: [],
    };
  },
  created() {
    const path = require("path");
    const images_list = require.context("@/assets/product", true, /\.(png|jpe?g|gif)$/); // 修改路径为你自己的图片文件夹路径
    const arr_img: Array<string> = [];
    images_list.keys().forEach(key => {
      const name = key.replace(/\.\//g, '');
      arr_img.push(name);
    });
    this.num = arr_img;
  },
  methods: {},
})
export default class product extends Vue {}
